#masthead {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    z-index: 3000;
    @include grid-media($nineHundo) {
        top: 80px;
        left: 40px;
        width: calc(100% - 80px);
    }
    @media all and (max-width:767px) {
        .wrapper {
            width: calc(89% - 20px);
        }
    }
}

#logo {
    width: 100%;
    max-width: 135px;
}

#spoke-nav {
    #logo {
        float: left;
    }

    hr {
        height: 0.25rem;
        width: 20%;
        margin: 0;
        background: $spokeOrange;
        border: none;
        transition: all 0.3s ease-in-out;
        opacity: 0;
    }

    .menu {
        float: right;
        padding-left: 0;
        width: calc(100% - 250px);
        max-width: 700px;
        @media all and (max-width:899px) {
            display: none;
        }

        li {
            list-style: none;

            a {
                padding: 10px 0;

                &.active {
                    &:hover {
                        cursor: default;
                    }
                }
            }
            @include grid-media($nineHundo) {
                display: inline;
                text-align: center;

                a {
                    display: inline-block;
                    width: 19%;

                    span {
                        display: none;
                    }
                }
            }
            /*
            &:first-of-type {
                a {}
                margin-left: 0;
            }

            &:last-of-type {
                a {
                    padding-right: 0;
                }
                margin-right: 0;
            }
            */
            &#menu-one:not(.active):hover ~ hr {
                opacity: 1;
            }

            &#menu-two:not(.active):hover ~ hr {
                margin-left: 19%;
                opacity: 1;
            }

            &#menu-three:not(.active):hover ~ hr {
                margin-left: 38%;
                opacity: 1;
            }

            &#menu-four:not(.active):hover ~ hr {
                margin-left: 57%;
                opacity: 1;
            }

            &#menu-five:not(.active):hover ~ hr {
                margin-left: 76%;
                opacity: 1;
            }
        }
    }
}

#menu-ribbon {
    background: $spokeOrange;
    width: 24px;
    padding: 7px;
    position: fixed;
    transform: translateY(-100px);
    transition: all 0.2s ease-in-out;
    top: 0;
    right: 2%;
    cursor: pointer;
    @include grid-media($nineHundo) {
        right: 40px;
    }

    &.on-screen {
        transform: translateY(0);
    }

    svg.spoke-oh {
        width: 100%;
        height: 25px;
        transition: transform $transition;
    }

    #toggly {
        padding-bottom: 8px;

        span {
            background: #fff;
            height: 3px;
            display: block;
            position: relative;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 3px 100%);
            transition: all 0.2s ease-in-out;

            &#two {
                width: calc(100% - 5px);
                transform: translateY(4px) translateX(5px);
            }

            &#three {
                width: calc(100% - 10px);
                transform: translateY(8px) translateX(10px);
            }
        }
    }

    &:hover {
        svg {
            transform: rotate(180deg);
        }
    }
}

.menu-open {
    overflow: hidden;

    #spoke-nav {
        position: fixed;
        padding: 0 50px;
        top: 0;
        left: 0;
        z-index: 3000;
        background: $spokeOrange;
        width: calc(100vw - 100px);
        height: 100vh;
        overflow: hidden;

        #logo {
            display: none;
        }

        .menu {
            overflow: scroll;
            overflow-x: hidden;
            float: none;
            max-height: calc(100vh - 20px);
            max-width: none;
            width: 100vw;
            padding-top: 20px;
            margin-top: 0;
            display: block;
            @include grid-media($oneK) {
                padding-top: 60px;
            }

            li {
                text-align: left;
                transition: all 0.2s ease-in-out;
                @include grid-media($nineHundo) {
                    display: block;
                }
                @include grid-media($oneK) {
                    width: 49%;
                    display: inline-block;
                }

                a {
                    opacity: 0.6;
                    color: $notWhite;
                    display: inline-block;
                    width: auto;
                    text-transform: none;
                    letter-spacing: -3.7px;
                    font: 68px/1.1em $poynterRoman;
                    @include grid-media($oneK) {
                        letter-spacing: -9.3px;
                        font: 122px/1.1em $poynterRoman;
                    }

                    span {
                        opacity: 0.6;
                        transition: opacity $transition;
                        display: block;
                        text-transform: uppercase;
                        font: 15px/1.2em $avenirDemi;
                        letter-spacing: 0;
                    }
                    @media all and (max-height:600px) and (max-width:999px) {
                        font-size: 45px;

                        span {
                            font-size: 12px;
                        }
                    }
                    @media all and (max-height:600px) and (min-width:1000px) {
                        font-size: 75px;
                        letter-spacing: -5.7px;

                        span {
                            font-size: 15px;
                        }
                    }
                }

                a {
                    &:hover {
                        opacity: 1;

                        span {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    #menu-ribbon {
        z-index: 3001;
        opacity: 0.6;
        transform: translateY(0);

        svg.spoke-oh {
            transform: rotate(180deg);
        }

        &:hover {
            opacity: 1;

            svg.spoke-oh {
                transform: rotate(180deg);
            }
        }

        #toggly {
            span {
                &#one,
                &#three {
                    clip-path: polygon(0 0,100% 0,100% 100%,0px 100%);
                }

                &#one {
                    transform: rotate(45deg) translateY(5px) translateX(6px);
                }

                &#two {
                    opacity: 0;
                }

                &#three {
                    transform: rotate(-45deg) translateY(1px) translateX(-1px);
                    width: 100%;
                }
            }
        }
    }
}

body.page-light {
    .menu a {
        color: $base;

        &.active {
            color: $spokeOrange;
        }
    }

    #hero {
        padding-bottom: 50px;
        @include grid-media($nineHundo) {
            padding-bottom: 15px;
        }
    }
}

body.mast-position {
    #masthead {
        position: relative;
        top: 0;
        padding-top: 40px;
        @include grid-media($nineHundo) {
            padding-top: 80px;
        }
    }
}

body.page-dark,
body.page-dark #masthead {
    background: $base;
}

body.page-dark {
    #masthead {
        &:after,
        &:before {
            content: '';
            background: $base;
            width: 40px;
            height: 100%;
            position: absolute;
            top: 0;
            left: -40px;
        }

        &:after {
            left: auto;
            right: -40px;
        }
    }
}

body:not(.page-light) {
    #logo svg {
        fill: #fff;
    }
}