.curtain {
    position: relative;
    z-index: 20;
    margin: 0 0 100vh;
}

.curtain-cover,
.curtain-wrapper {
    position: fixed;
    width: 100vw;
    top: 0;
}

.curtain-wrapper {
    z-index: 10;
    background: #fff;

    .curtain-veil {
        position: fixed;
        background: rgba(0,0,0,.8);
        z-index: 1;
        width: 100%;
        height: 100vh;
        left: 0;
        display: none;
        opacity: 1;
    }

    > .curtain-veil + div {
        //> div:not(.curtain-veil):first-of-type {
        padding-top: calc(20vh - 80px);
        padding-bottom: 50px;
        position: relative;
        margin-bottom: -3px;

        > div.wrapper {
            position: relative;
            z-index: 2;
        }
    }
}

.curtain-cover {
    background: rgba(0,0,0,.8);
    z-index: 4;
    height: 100vh;
    left: 0;
    display: none;
    opacity: 1;
}

.index {
    padding-bottom: 100vh;

    .curtain {
        height: 100vh;
        overflow: hidden;
        width: 100% !important;
        background: #fff;

        .hide-over {
            overflow: hidden;
            position: relative;
            //transition: transform 1s ease-in-out 0ms,width 1s ease-in-out 0ms,height 1s ease-in-out 0ms,;
            transition: all 700ms ease-in-out 0ms;
            //transform: translateX(-10%);
            width: 100vw;
            height: 100vh;
            margin: 0 auto;
        }
    }
}

.reveal-bottom-to-top {
    position: relative;

    .overlay {
        position: absolute;
        background: #fff;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        transition: transform 0.8s ease-in-out 0.3s,-webkit-transform 0.8s ease-in-out 0.3s;
        transform-origin: left top;
    }

    &.blackish {
        .overlay {
            background: $blackish;
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
ul {
    &.fade-in-up {
        opacity: 0;
        transform: translateY(60px);
        transition: 1500ms opacity, 1250ms transform;
    }
}

.fade-in-up {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ol,
    p,
    ul {
        opacity: 0;
        transform: translateY(60px);
        transition: 1500ms opacity, 1250ms transform;
    }
}

.fade-up {
    &:not(.columns) {
        opacity: 0;
        transform: translateY(100px);
        transition: opacity 1s ease,transform 1s ease;
    }

    &.columns {
        > div {
            opacity: 0;
            transform: translateY(100px);
            transition: opacity 1s ease,transform 1s ease;

            &:nth-of-type(2) {
                transition-delay: 0.3s;
            }

            &:nth-of-type(3) {
                transition-delay: 0.6s;
            }

            &:nth-of-type(4) {
                transition-delay: 0.9s;
            }

            &:nth-of-type(5) {
                transition-delay: 1.2s;
            }

            &:nth-of-type(6) {
                transition-delay: 1.5s;
            }

            &:nth-of-type(7) {
                transition-delay: 1.8s;
            }

            &:nth-of-type(8) {
                transition-delay: 2.1s;
            }
        }
    }

    &#hero__obj {
        //transform: translate(50%,70px) !important;
    }

    &#hero__obj2 {
        //transform: translate(50%,58px) !important;
    }
}

.fade-in-move-right {
    transform-origin: left top;
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity 0.5s ease-in-out,transform 0.5s ease-in-out;

    .arrow-link {
        transform: translateX(-75px) rotate(90deg);
        transition-delay: 0.4s;
    }
}

.fade-in-move-left {
    transform-origin: right top;
    opacity: 0;
    transform: translateX(50px);
    transition: opacity 0.5s ease-in-out,transform 0.5s ease-in-out;

    .arrow-link {
        transform: translateX(75px) rotate(90deg);
        transition-delay: 0.4s;
    }
}

.delay {
    transition-delay: 1.2s;

    &-short {
        transition-delay: 0.7s;

        .arrow-link {
            transition-delay: 1s !important;
        }
    }
}

.is-visible {
    &.fade-in-up {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        ol,
        p,
        ul {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &.fade-up {
        &:not(.columns) {
            transform: translate(0);
            opacity: 1;
        }

        &.columns {
            > div {
                transform: translate(0);
                opacity: 1;
            }
        }

        &#hero__obj {
            transform: translate(50%, -50%) !important;
            @media all and (max-height:500px) {
                transform: translate(50%, -40%) !important;
            }
        }

        &#hero__obj2 {
            transform: translateX(50%) translateY(-50%) translateY(12px) !important;
            -ms-transform: translateX(50%) translateY(-50%) translateY(12px);
            -o-transform: translateX(50%) translateY(-50%) translateY(12px) !important;
            @media all and (max-height:500px) {
                transform: translateX(50%) translateY(-42%) translateY(6px) !important;
                -ms-transform: translateX(50%) translateY(-42%) translateY(6px);
                -o-transform: translateX(50%) translateY(-42%) translateY(6px) !important;
            }
        }
    }

    &.reveal-bottom-to-top {
        .overlay {
            transform: scaleY(0);
        }
    }

    &.fade-in-move-left,
    &.fade-in-move-right {
        transform: translateX(0);
        opacity: 1;

        .arrow-link {
            opacity: 1;
            transform: translateX(0) rotate(90deg);
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
ul {
    &.fade-in-up.is-visible {
        transform: translateY(0);
        opacity: 1;
    }
}

.scale-to-size {
    transition: transform 1.2s ease-in 0.3s;
    transform: scale(1.2);

    img {
        transition: transform 1.2s ease-in-out 0.3s;
        transition-delay: 1.2s;
        transform: scale(1);
    }
}

.index {
    #masthead {
        opacity: 0;
        transition: opacity 1s ease-in-out;
    }
}

.loaded {
    .scale-to-size {
        transform: scale(1);

        img {
            transform: scale(1.07);
        }
    }

    &.index {
        #masthead {
            opacity: 1;
        }

        .curtain {
            .hide-over {
                //transform: translateX(0);
                @include grid-media($nineHundo) {
                    margin: 40px auto;
                    //top: 40px;
                    //left: 40px;
                    height: calc(100vh - 80px);
                    width: calc(100vw - 80px);
                }
            }
        }
    }
}