.one-wide {
    @include grid-media($nineHundo) {
        header {
            @include grid-column(3);
        }

        .entry-body {
            @include grid-shift(0.5);
            @include grid-column(8.5);
        }
    }
}

.one-wide__img {
    width: 100%;
}

.stats {
    text-align: center;
    @media all and (max-width:699px) {
        .three-column {
            margin-bottom: 35px;
        }
    }

    .number {
        font-size: 36px;
        margin: 20px 0 0;
        font-family: $avenirDemi;
        @include grid-media($sevenHundo) {
            font-size: 56px;
        }
    }
}

.mobile {
    padding: 50px 0 0;
    display: flex;
    flex-wrap: wrap;
    @media all and (max-width:999px) {
        .two-thirds-column {
            order: 2;
        }

        .one-third-column,
        .two-thirds-column {
            width: 100%;
            margin-left: 0;
        }

        .one-third-column {
            text-align: center;
            left: 0 !important;
        }
    }

    .one-third-column {
        margin-bottom: 20px;
    }
}

.blockquote {
    background: transparent !important;
    padding: 70px 0 !important;
    @include grid-container;
    @include grid-media($nineHundo) {
        padding: 80px 0 !important;
    }

    blockquote {
        color: #fff;
        border-left: 10px solid $spokeOrange;
        padding-left: 30px;
        margin-left: 20px;
        @include grid-media($sevenHundo) {
            font-size: 40px;
            letter-spacing: -1.8px;
            @include grid-shift(1);
            @include grid-column(11);
        }
        @include grid-media($eightHundo) {
            @include grid-shift(2);
            @include grid-column(10);
        }
        @include grid-media($oneK) {
            @include grid-shift(6);
            @include grid-column(6);
        }
    }
}

#body {
    > article {
        > blockquote {
            @include grid-media($sevenHundo) {
                font-size: 40px;
                letter-spacing: -1.8px;
                @include grid-column(11);
                float: right;
                margin-right: 2%;
            }
            @include grid-media($eightHundo) {
                @include grid-column(10);
                float: right;
                margin-right: 2%;
            }
            @include grid-media($oneK) {
                //@include grid-shift(6);
                @include grid-column(6);
                float: right;
                left: 0;
                margin-right: 5%;
            }
            @media all and (min-width:1200px) {
                margin-right: calc(50vw - 625px);
            }
        }

        > blockquote {
            color: #fff;
            border-left: 10px solid $spokeOrange;
            padding-left: 30px;
            margin-left: 20px;
        }
    }
}

.galleryblock {
    display: flex;

    .two-thirds-column {
        padding-top: 5px;
        @include grid-media($sevenHundo) {
            padding-top: 30px;
        }
    }
    @include grid-media($sevenHundo) {
        .two-thirds-column {
            order: 2;
        }

        .one-third-column {
            order: 1;
        }
    }

    img {
        width: calc(50% - 11px);
        float: left;
        margin: 0 0 20px 10px;

        &:nth-of-type(odd) {
            clear: left;
            margin: 0 10px 20px 0;
        }
    }
}

.fullwidth {
    clear: both;

    .columns {
        .half {
            width: 100%;
            margin-left: 0;
            @include grid-media($sevenHundo) {
                margin-left: 0;
                width: 50%;
            }
        }
    }

    img {
        width: 100%;
        height: auto;
        display: block;
    }
    @include grid-media($sixHundo) {
        width: calc(96% - -20px);
        margin: 0 auto;
    }
    @include grid-media($nineHundo) {
        width: calc(100% - 60px);
    }
    @media all and (min-width:1200px) {
        width: calc(100% - 174px);
    }
}

.widget {
    &:not(.link) {
        padding: 90px 0;
        @include grid-media($sevenHundo) {
            padding: 130px 0;
        }
    }
    margin-bottom: 10px;
    @media all and (max-width:699px) {
        &:nth-of-type(odd) {
            margin-bottom: 0;
        }
    }

    blockquote {
        color: #fff;
        margin: 0;
    }

    a {
        display: block;
        padding: 90px 0;
        z-index: 2;
        position: relative;
        @include grid-media($sevenHundo) {
            padding: 130px 0;
        }
    }

    &.link {
        overflow: hidden;

        &:before {
            content: '';
            background: rgba(148,148,148,.3);
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 0;
            transform: translateX(-100%);
            transition: all $transition;
        }

        svg {
            left: 0;
            transition: all $transition;
            position: relative;
        }

        &:hover {
            &:before {
                transform: translateX(0);
            }

            svg {
                left: 8px;
            }
        }
    }

    .wrapper {
        width: calc(100% - 100px);
    }
}

.captionblock .wrapper {
    align-items: center;
}