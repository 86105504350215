html {
    font-size: 16px;
    line-height: 1.3em;
    color: $base;
    font-family: Arial, serif;

    &.fonts-loaded {
        font-family: $avenirRegular;

        h1,
        h2,
        h3,
        h4 {
            font-family: $avenirBold;
        }

        h2:not(.alpha-small):not(.sans-serif) {
            font-family: $poynterRoman;
        }

        .alpha-small,
        .alpha-small-larger,
        .likert caption,
        .wufoo label.desc,
        .wufoo legend.desc,
        p strong {
            font-family: $avenirBold;

            &.bigger,
            &.larger,
            .bigger,
            .larger {
                font-family: $avenirBold !important;
            }
        }

        .larger,
        .preamble,
        .roman,
        .widget .main-title {
            font-family: $poynterRoman;
        }

        .sans-serif,
        cite {
            font-family: $avenirBold;
        }
    }
}

a {
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

h1 {
    font-size: 32px;
    line-height: 1.05em;
    @include grid-media($sixHundo) {
        font-size: 40px;
    }
}

h1,
h2,
h3,
h4 {
    //font-family: $avenirBold;
    font-family: Arial;

    &.subtitle {
        font-size: 12px;
    }
}

h2:not(.alpha-small):not(.sans-serif) {
    font: 32px/1.05em "Times New Roman", serif;
    letter-spacing: -1.5px;
    @include grid-media($sevenHundo) {
        font-size: 36px;
    }

    &.smaller {
        font-size: 16px;
        margin: 40px 0 20px;
        @include grid-media($sevenHundo) {
            font-size: 16px;
        }
    }

    &.medium {
        font-size: 24px;
        line-height: 36px;
    }
}

p {
    margin: calc(1em + 8px) 0;
    line-height: 1.5em;

    &.small {
        font-size: 12px;
    }

    strong {
        //font-family: $avenirBold;
    }
}

.alpha-small,
.alpha-small-larger,
.likert caption,
.wufoo label.desc,
.wufoo legend.desc {
    text-transform: uppercase;
    color: $spokeOrange;
    font-size: 12px;
    letter-spacing: 0;
    //font-family: $avenirBold;
    font-family: "Open Sans", "Helvetica", Arial, sans-serif;
    margin: 0;

    &.bigger,
    &.larger,
    .bigger,
    .larger {
        font-size: 16px;
        letter-spacing: 0;
        //font-family: $avenirBold !important;
        font-family: "Open Sans", "Helvetica", Arial, sans-serif !important;
    }
}

.alpha-small .larger,
.alpha-small-larger {
    font-size: 14px;
    letter-spacing: 2.5px;
}

.main-title {
    font-size: 42px;
    letter-spacing: -1.75px;
    font-weight: 400;
    line-height: 1em;
    margin: 10px 0;
    @include grid-media($sevenHundo) {
        font-size: 48px;
    }
}

.base {
    color: $base;
}

.white {
    color: #fff;
}

.spokeOrange {
    color: $spokeOrange;
}

.blue {
    color: $blue !important;
}

.roman {
    //font-family: $poynterRoman;
    font-family: "Times New Roman", Times, serif;
    font-weight: 400;
}

.upper {
    text-transform: uppercase;
}

.preamble {
    //font: 22px/1.2em $poynterRoman;
    font: 22px/1.2em "Times New Roman", Times, serif;
    letter-spacing: -1.5px;
    margin: 10px 0;
    line-height: 1.5em;
    @include grid-media($sevenHundo) {
        font-size: 24px;
    }

    a {
        color: $spokeOrange;

        &:hover {
            color: $spokeOrangeHover;
        }
    }
}

.larger {
    //    p {
    //font: 26px/1.2em $poynterRoman;
    font: 26px/1.2em "Times New Roman", Times, serif;
    letter-spacing: -0.7px;
    @include grid-media($sevenHundo) {
        font-size: 32px;
        letter-spacing: -1.1px;
    }

    &.roman {
        margin: 10px 0;
        line-height: 1.5em;
        @include grid-media($sevenHundo) {
            font-size: 24px;
        }
    }
    //}
}

h1,
h2 {
    .larger {
        font-size: 42px;
        @include grid-media($sevenHundo) {
            font-size: 48px;
        }
    }
}

.sans-serif {
    //font-family: $avenirBold;
    font-family: "Times New Roman", Times, serif;
}
/*
.roman {
    font-family: $poynterRoman;
}
*/
blockquote {
    display: block;
    quotes: "\201C" "\201D" "\2018" "\2019";
    //font: 30px/1.2em $poynterRoman;
    font: 30px/1.2em "Times New Roman", Times, serif;
    letter-spacing: -1.5px;
    @include grid-media($sevenHundo) {
        font-size: 32px;
    }
}

cite {
    text-transform: uppercase;
    //font: 12px/16px $avenirBold;
    font: 12px/16px "Helvetica", Arial, serif;
    text-align: center;
    display: block;
    margin-top: 20px;
}

.quotes {}

.medium {
    margin-bottom: 50px;

    p {
        font: 18px/1.5em $poynterRoman;
        letter-spacing: -1px;
        @include grid-media($sevenHundo) {
            font-size: 24px;
            letter-spacing: -1.5px;
        }
    }
}

.align-right {
    text-align: right;
}

.align-center,
.center-text {
    text-align: center;

    cite,
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    quoteblock,
    span {
        text-align: center;
    }
}

.menu {
    font: 12px/1em $avenirBold;

    a {
        padding: 5px;
        text-transform: uppercase;
        color: #fff;

        &.active {
            color: $spokeOrange;
        }
    }
}

.page-work {
    .menu {
        a {
            color: $base;
        }
    }
}

.small {
    font-size: 12px;
}

.widget {
    .main-title {
        //font: 32px/1.1em $poynterRoman;
        font: 32px/1.1em "Times New Roman", Times, serif;
    }
}