.hover-cover {
    background: $spokeOrange;
    position: absolute;
    width: calc(100% - 60px);
    bottom: 0;
    padding: 70px 30px;
    transform: translateY(100%);
    z-index: 4;
    transition: transform $transition;

    a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
        color: #fff;
    }

    .roman {
        font-size: 32px;
        line-height: 1em;
        letter-spacing: -1.33px;
        margin: 9px 0;
    }

    span.arrow-link {
        svg.arrow-link {
            height: 11px;
            top: 2px;
            left: 15px;
            position: relative;
        }
    }
}

.hover-zoom {
    position: relative;
    overflow: hidden;
    @include grid-container;

    a {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    img,
    p {
        position: relative;
        z-index: 2;
    }

    .target-scale {
        padding-top: 110%;
        z-index: 0;
        transition: all 0.7s ease-in-out;
    }

    .mark {
        position: relative;
        z-index: 3;
        height: 45%;
        margin: 0;
        padding: 0;
        border: 0;
        transform: translateY(50%);
        transition: transform $transition;

        img {
            max-width: 200px;
            max-width: 200px;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
        }
    }

    &:hover {
        .target-scale {
            transform: scale(1.25);
        }

        .mark {
            transform: translateY(5%);
        }

        .hover-cover {
            transform: translateY(0%);
        }
    }
}

.work-preview {
    background: $blackish;
    @media all and (min-width:1400px) {
        > .three-column {
            width: 25% !important;

            &:nth-of-type(3n + 1) {
                clear: none !important;
            }

            &:nth-of-type(4n + 1) {
                clear: left !important;
            }
        }
    }
}