// VARS
@import url("https://use.typekit.net/ipl5wwe.css");
@font-face {
    font-family: 'AvenirNext-DemiBold';
    src: url("/dist/fonts/AvenirNext-DemiBold.woff") format('woff'), url("/dist/fonts/AvenirNext-DemiBold.svg#AvenirNext-DemiBold") format('svg'), url("/dist/fonts/AvenirNext-DemiBold.eot?#iefix") format('embedded-opentype'), url("/dist/fonts/AvenirNext-DemiBold.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'AvenirNext-Bold';
    src: url("/dist/fonts/AvenirNext-Bold.woff") format('woff'), url("/dist/fonts/AvenirNext-Bold.svg#AvenirNext-Bold") format('svg'), url("/dist/fonts/AvenirNext-Bold.eot?#iefix") format('embedded-opentype'), url("/dist/fonts/AvenirNext-Bold.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'AvenirNext-Regular';
    src: url("/dist/fonts/AvenirNext-Regular.woff") format('woff'), url("/dist/fonts/AvenirNext-Regular.svg#AvenirNext-Regular") format('svg'), url("/dist/fonts/AvenirNext-Regular.eot?#iefix") format('embedded-opentype'), url("/dist/fonts/AvenirNext-Regular.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
$avenirBold: 'AvenirNext-Bold', Arial, sans-serif;
$avenirDemi: 'AvenirNext-DemiBold', Arial, sans-serif;
$avenirRegular: 'AvenirNext-Regular', Arial, sans-serif; // RADIUS, etc
$noto: 'Noto Serif', serif; ///////// COLORS ///////
$poynterRoman: poynter-oldstyle-text, "Times New Roman", serif;
$poynterItalic: poynter-oldstyle-text, "Times New Roman", serif; //$poynterRoman: 'poynter-oldstyle-display', Arial, 'Times New Roman', serif;
//$poynterItalic: 'poynter-oldstyle-display', Arial, 'Times New Roman', serif;
$spokeOrange: #E64936;
$spokeOrangeHover: #fd290f;
$base: #15161C;
$blackish: #15161C;
$blue: #378ACA;
$bgLight: #F9F9F9;
$notGray: #B7B9C4;
$notBlack: #3E3E42;
$notWhite: #F6F6F6; //////// SIZES ////////
$transition: 0.3s ease-in-out;
$sixHundo: (
    media: 600px
);
$sevenHundo: (
    media: 700px
);
$eightHundo: (
    media: 800px
);
$nineHundo: (
    media: 900px
);
$oneK: (
    media: 1000px
);