.main-container {
    background: #fff;
    position: relative;
    z-index: 2;
}

.wrapper {
    width: 96%;
    clear: both;
    margin: 0 auto;
    max-width: 1400px;

    &.narrow {
        max-width: 1100px;
        width: 90%;
    }
}

.clearfix::after {
    content: "";
    display: table;
    width: 100%;
    clear: both;
}

.section {
    padding: 50px 0;

    &.noPaddingBottom {
        padding-bottom: 0;
    }
}

.flex {
    flex-wrap: wrap;

    &.collapse-at-six {
        display: flex;
    }
}

.columns {
    @include grid-container;
    @include grid-media($sixHundo) {
        &:not(.mobile):not(.no-collapse):not(.galleryblock) {
            @include grid-collapse;
        }
    }
    @include grid-media($sixHundo) {
        &.collapse-at-six {
            @include grid-collapse;
        }
    }

    > div,
    > article {
        position: relative;
    }

    &.fill {
        @include grid-collapse;
    }

    .half {
        @include grid-column(6);
    }

    &.circle-border {
        > div,
        > article {
            box-sizing: border-box;
            position: relative;
            margin-bottom: 45px;
            border-bottom: 1px solid $notBlack;

            &:after {
                content: '';
                width: 7px;
                height: 7px;
                border: 1px solid $notBlack;
                position: absolute;
                bottom: -4.5px;
                right: -8px;
                border-radius: 50%;
            }

            &.three-column {
                @include grid-media($sevenHundo) {
                    padding-bottom: 20px;
                    padding-right: 20px;
                    border-right: 1px solid $notBlack;
                    border-bottom: none;
                }
                @include grid-media($nineHundo) {
                    padding-right: 20px;
                }
                @include grid-media($sevenHundo) {
                    &:after {
                        bottom: -8px;
                        right: -4.5px;
                    }
                }
                @media all and (min-width:700px) {
                    &:nth-of-type(3n) {
                        border-right: none;

                        &:after {
                            content: none;
                        }
                    }
                }
            }

            &.four-column {
                @include grid-media($sixHundo) {
                    padding-bottom: 20px;
                    padding-right: 20px;
                    border-right: 1px solid $notBlack;
                    border-bottom: none;
                }
                @include grid-media($nineHundo) {
                    padding-right: 20px;
                }
                @include grid-media($sixHundo) {
                    &:after {
                        bottom: -8px;
                        right: -4.5px;
                    }
                }
                @media all and (max-width:899px) {
                    &:nth-of-type(even) {
                        border-right: none;

                        &:after {
                            content: none;
                        }
                    }
                }
                @include grid-media($nineHundo) {
                    &:last-of-type {
                        border-right: none;

                        &:after {
                            content: none;
                        }
                    }
                }
            }
        }
    }
    @include grid-media($sevenHundo) {
        .one-fourth-column {
            @include grid-column(3);
        }

        .three-fourths-column {
            @include grid-column(9);
        }

        .two-thirds-column {
            @include grid-column(8);

            &.push-left {
                @include grid-shift(0.5);
            }
        }

        .one-third-column {
            @include grid-column(3.5);

            &.push-left {
                @include grid-shift(0.5);
            }
        }
    }

    .three-column {
        @include grid-media($sevenHundo) {
            @include grid-column(4);

            &.full {
                width: 33.3333%;
                float: left;
                margin-left: 0;
            }
        }
    }

    .four-column {
        margin-bottom: 20px;
        @include grid-media($sixHundo) {
            @include grid-column(6);
        }
        @include grid-media($nineHundo) {
            @include grid-column(3);
        }
    }
}
@include grid-media($sevenHundo) {
    .sevenHundo {
        &.flex {
            display: flex;
            position: relative;
        }

        &.push-bottom {
            position: absolute;
            bottom: 0;
        }
    }
}

.absolute-cover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.center-center-me {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
//// GENERAL //////

.orangeBG {
    background: $spokeOrange;

    a,
    cite,
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    quoteblock,
    span {
        color: #fff;
    }
}

.bg-blackish,
.blackBG {
    background: $blackish;

    > p {
        color: #fff;
    }
}

.blackish {
    background: $blackish !important;
    overflow: hidden;

    > p,
    > h1,
    a,
    h2,
    h3,
    h4,
    h5,
    h6,
    li {
        color: #fff;
    }
}

.entry-content {
    @include grid-container;
    //overflow: hidden;
}

.light-bg {
    background: $bgLight;
}

.quotes {
    padding: 100px 0 !important;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    //margin: 10px;
    @include grid-media($nineHundo) {
        width: calc(100% - 80px);
        //margin: 40px auto;
        margin: 40px auto;
    }
}

.cta-block {
    text-align: center;

    p {
        font: 12px/1.2em $avenirBold;
        text-transform: uppercase;
        color: #4a4a4a;
        margin: 0 0 20px;
    }
    @include grid-media($sevenHundo) {
        .wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        p {
            margin: 0;
        }

        .button {
            margin-left: 25px;
        }
    }
}

.button,
.wufoo input.btTxt.submit {
    padding: 15px 40px;
    line-height: 1em;
    font: 10px/1em $avenirBold;
    letter-spacing: 2.53px;
    text-transform: uppercase;

    &.orange,
    &.spokeOrange {
        background: $spokeOrange;
        color: #fff;

        &:hover {
            background: $spokeOrangeHover;
        }
    }

    &.ghost {
        padding: 14px 40px;
        border: 1px solid $spokeOrange;
        color: $spokeOrange;
    }
}

.visually-hidden {
    /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

body.index {
    .light-gray.section.entry-content > .wrapper.narrow,
    .white-bg.section.entry-content > .wrapper.narrow {
        max-width: 786px;
    }
}

body.single {
    #body > article {
        z-index: 20;
        position: relative;
        overflow: hidden;
    }

    &:not(.page-dark) #body > article {
        background: #fff;
    }

    &.page-dark #body > article {
        background: $blackish;
    }
}