.pages,
.single {
    #body > article > h1,
    #body > article > h2,
    #body > article > h3,
    #body > article > h4,
    #body > article > h5,
    #body > article > h6,
    #body > article > ol,
    #body > article > p,
    #body > article > ul,
    .entry-content {
        padding: 20px 35px 40px;
        margin: 0 auto 10px;
        max-width: 1250px;
        @include grid-media($sixHundo) {
            width: calc(96% - 50px);
        }

        &.block {
            padding-top: 70px;
            display: flex;
            flex-wrap: wrap;
            @media all and (max-width:899px) {
                aside {
                    order: 2;
                }

                #primary {
                    order: 1;
                }
            }
        }

        &.noPaddingBottom {
            padding-bottom: 0;
            margin-bottom: 0;
        }

        &.noPaddingTop {
            padding-top: 0;
        }
        @include grid-media($nineHundo) {
            width: calc(100% - 130px);
        }
        @media all and (min-width:1200px) {
            width: calc(100% - 245px);
        }
        /*
        h2 {
            font-size: 16px;
            margin-top: 40px;
            font-family: $avenirBold;

            &.roman {
                font-family: $poynterRoman;
                font-size: 24px;
                line-height: 1.5em;
            }
        }
        */
        header {
            h2 {
                margin-top: 16px;
            }
        }
    }

    .entry-content {
        background: #fff;

        &.Black-ish {
            background: $base;
            color: #fff;
            padding-left: 0;
            padding-right: 0;
            width: 90%;
            position: relative;

            &:after,
            &:before {
                content: '';
                background: $base;
                width: 200%;
                height: 100%;
                position: absolute;
                top: 0;
                left: -200%;
            }

            &:after {
                left: 100%;
            }
            @media all and (min-width:1200px) {
                width: calc(100% - 195px);
            }

            .columns {
                @include grid-media($sevenHundo) {
                    @include grid-collapse;
                }
            }
        }
    }

    #body > article > h1,
    #body > article > h2,
    #body > article > h3,
    #body > article > h4,
    #body > article > h5,
    #body > article > h6,
    #body > article > ol,
    #body > article > p,
    #body > article > ul {
        @include grid-media($sixHundo) {
            width: 90%;
        }
        @media all and (min-width:1200px) {
            width: calc(100% - 200px);
        }
    }

    #secondary {
        ul {
            list-style: none;
            padding-left: 0;
            font-size: 12px;
            line-height: 1.1em;
            margin-left: -8px;
            @include grid-media($nineHundo) {
                margin-left: 0;
            }

            li {
                display: inline-block;
                margin: 8px;
                @include grid-media($nineHundo) {
                    display: block;
                    margin: 8px 0;
                }
            }
        }
    }
    @include grid-media($nineHundo) {
        .block {
            display: flex;
        }

        #secondary {
            //@include grid-column(3);
            width: calc(25% - 25px);
            float: left;
            margin-left: 0;
        }

        #primary {
            position: relative;
            left: 25px;
            width: calc(75% - 25px);
            float: left;
            margin-left: 20px;
        }
    }
}

.pages > div:first-of-type:not(#hero) {
    position: relative;
    top: 200px;
    margin-bottom: 210px;
}