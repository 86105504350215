#hero {
    padding: 105px 0 0;
    clear: both;

    h1 {
        color: $blue;
    }
    /*
    @include grid-media($nineHundo) {
        width: calc(100% - 80px);
        margin: 40px auto;
    }
    */
}

.index {
    min-height: 200vh;

    #hero {
        clip: rect(0, auto, auto, 0);
        overflow: hidden;
        position: absolute;
    }

    .hide-over {
        /*background: url("../img/wood.jpg");
        background-size: cover;
        */
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    #hero__obj2 {
        img {
            /*max-width: 300px;*/
            max-width: calc(80vh / 2.5);
        }
    }

    #hero__obj {
        /*max-width: 253px;*/
        max-width: calc(80vh / 3);
        width: 100%;
    }
    @media all and (max-height:500px) {
        #hero__obj2 {
            img {
                max-width: 42vh;
            }
        }

        #hero__obj {
            max-width: 36vh;
        }
    }
    /*
    @media all and (max-height:520px) {
        #hero__obj {
            img {
                max-width: 141px;
            }
        }

        #hero__obj2 {
            img {
                max-width: 151px;
            }
        }

        #hero__obj,
        #hero__obj2 {
            video {
                left: -69px;
            }
        }
    }
    @media all and (max-height:450px) {
        #hero__obj {
            //top: 111px;
            transform: translate(50%, -4px);
        }

        #hero__obj2 {
            //top: 115px;
        }
    }
    */
}
/*
.webpack {
    &.index {
        .hide-over {
            background: url("../img/wood.webp");
        }
    }
}
*/
#hero__obj,
#hero__obj2 {
    position: absolute;
    z-index: 30;
    right: 50%;
    transform: translate(50%, 0px);
    //top: 200px;
    top: 50%;
    overflow: hidden;
    transition: transform 1s ease;

    &.hide {
        opacity: 0;
    }

    img {
        position: relative;
        z-index: 10;
    }

    video {
        position: absolute;
        width: auto;
        height: 100%;
        /*lheight: calc(100% - 25px);
        eft: -94px;
        top: 6px;*/
        left: 0;
        top: 0;
        transform: translateX(-19%);
        z-index: 17;
    }
}
/*
#hero__obj {
    @media all and (max-width:356px) {
        background: #000;

        video {
            left: -49%;
        }
    }
    @media all and (max-width:356px) and (min-height:850px) {
        //top: 22.6%;
    }
    @media all and (max-width:400px) {
        margin-left: 3%;

        img {
            //max-width: calc(100% + 6px);
            display: block;
        }

        video {
            top: 4px;
            height: calc(100% + -17px);
        }
    }
}
*/
#hero__obj2 {
    transform: translate(50%, -22px);
    @media all and (max-width:400px) {
        img {
            //max-width: calc(100% + 8px);
        }
    }
}

#heroVideo,
#phoneInner,
#phoneOuter {
    opacity: 0;

    &.show {
        opacity: 1;
    }
}

#phoneInner {
    //background: #000;
}

.index {
    #hero {
        padding: 0;
    }

    #hero__obj {
        img.show {
            background: #000;
        }
    }
}

.page-dark {
    #hero {
        padding-bottom: 60px;

        h1,
        p {
            color: #fff;
        }
        @include grid-media($nineHundo) {
            padding-bottom: 0;

            .wrapper {
                display: flex;
            }

            #heroImg,
            #text {
                width: 50%;
            }

            #heroImg {
                order: 2;
            }
        }
    }
}