#tartan {
    /*background: url("../img/tartan.jpg") repeat-x;*/
    height: 115px;
    background-size: 100%;
    margin-bottom: 40px;
    @media all and (min-width:500px) {
        margin-bottom: 75px;
    }
    @media all and (max-height:605px) {
        margin-bottom: 35px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: none !important;
    }
}

#colophon {
    background: #fff;
    padding: 0 0 50px;
    text-align: center;

    #definition {
        display: none;
    }
    @media all and (min-width:750px) {
        text-align: left;

        #definition {
            display: inline;
        }
    }

    svg {
        max-width: 130px;
        max-height: 22px;
    }

    .block {
        margin-left: 0;
        @media all and (min-width:750px) {
            width: calc(100% - 290px);
            display: inline-block;
            margin-left: 20px;

            &.small {
                width: 210px;
            }
        }
    }

    img {
        max-width: 130px;
    }

    a {
        color: $base;
    }

    strong {
        font-family: $avenirBold;
        font-weight: 500;
    }

    ul {
        list-style: none;
        padding-left: 0;
        letter-spacing: -.26px;
        margin-top: 30px;
        font-size: 12px;
        @include grid-media($eightHundo) {
            font-size: 14px;
        }
        @media all and (max-height:605px) {
            margin-top: 15px;
        }
        @media all and (max-height:390px) and (max-width:749px) {
            &#tag {
                display: none;
            }
        }
    }

    ul:not(#contact) {
        text-transform: uppercase;
    }

    #contact {
        margin-top: 0;
        clear: right;
    }

    ul:not(.social) {
        li {
            margin: 5px 0;
        }

        a:hover {
            color: $spokeOrange;
        }
    }

    .social {
        li {
            display: inline-block;
            margin-right: 13px;

            &:last-of-type {
                margin-right: 0;
            }
        }

        a {
            display: block;

            svg {
                fill: $base;
                width: 18px;
                height: 18px;
                transition: all $transition;
            }

            &:hover {
                svg {
                    fill: $spokeOrange;
                }
            }
        }

        li#facebook-link {
            a svg {
                width: 11px;
            }
        }
    }
}
//.index {

#colophon {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 0;
}
//}

#copyright {
    clear: both;
    margin-bottom: -20px;
    font-size: 8px;
    text-transform: uppercase;
    width: calc(100% - 40px);
    margin-left: 20px;
}