svg {
    &.small {
        max-width: 80px;
        max-height: 80px;
        padding-bottom: 20px;
    }
}

svg.arrow-link {
    width: 11px;
    height: 14px;
    fill: #fff;
    transform: rotate(90deg);
}

img {
    &.lazy {
        /*    width: 14px;
        height: 14px;
        max-width: 14px !important;
        opacity: 0.5;*/
    }

    &.align-right {
        float: right;
    }

    &.align-center {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    &.spoke-icon {
        max-width: 50px;
    }

    &.pad-bottom {
        margin-bottom: 50px;
    }
}

#to-top {
    position: fixed;
    bottom: 10px;
    right: 2%;
    width: 16px;
    height: 19px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    background: rgba(255,255,255,1);
    border-radius: 3px;
    padding: 5px;
    @include grid-media($nineHundo) {
        right: 40px;
    }

    &.show {
        opacity: 0.7;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }
}
/*
.gallery {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
        text-align: center;
        margin-bottom: 20px;
        @include grid-column(6);
        margin-left: 0;
        padding-left: 20px;
        border-bottom: 2px dotted #dddddd;

        &:nth-of-type(odd) {
            margin-left: 0;
        }
        @include grid-media($sixHundo) {
            @include grid-column(4);

            &:nth-of-type(odd) {
                margin-left: 20px;
            }

            &:nth-of-type(3n+1) {
                margin-left: 0;
            }
        }
        @include grid-media($eightHundo) {
            @include grid-column(3);

            &:nth-of-type(3n+1) {
                margin-left: 20px;
            }

            &:nth-of-type(4n+1) {
                margin-left: 0;
            }
        }
    }

    img {
        height: auto;
        width: 100%;
        max-width: 155px;
        @media all and (min-width:800px) {
            max-width: 175px;
        }

        &#delk {
            max-height: 55px;
            width: auto;
        }
    }
    @media all and (max-width:799px) {
        #DelkLogo img {
            max-width: 100px;
        }
    }
}
*/
.gallery {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    list-style: none;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    -ms-grid-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    -ms-grid-rows: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
    overflow: hidden;

    li {
        display: flex;
        justify-content: center;
        padding: 15px 0;
        position: relative;
        //border-image-source: url("/dist/img/circle-border.svg");
        /*border-image-slice: 9 9 9 9 fill;
        border-image-width: 0 2px 2px 2px;
        border-image-outset: 0 0 0 0;
        border-image-repeat: round round;
        */
        border-style: solid;
        border-width: 0 2px 2px 2px;
        -moz-border-image: url("/dist/img/circle-border.svg") 0 9 9 9 repeat repeat;
        -webkit-border-image: url("/dist/img/circle-border.svg") 0 9 9 9 repeat repeat;
        -o-border-image: url("/dist/img/circle-border.svg") 0 9 9 9 repeat repeat;
        border-image: url("/dist/img/circle-border.svg") 0 9 9 9 repeat repeat;
        border-top: none;
        //border-right: none;
        @media all and (max-width:599px) {
            &:nth-of-type(odd) {
                border-image-width: 0 0 2px 0;
            }

            &:nth-of-type(even) {
                border-image-width: 0 0 2px 2px;
            }

            &:nth-last-of-type(1) {
                border-image-width: 0 0 0 2px;
            }

            &:nth-last-of-type(2) {
                border-image-width: 0 0 0 0;
            }

            &:nth-of-type(1) {
                -ms-grid-row: 1;
                -ms-grid-column: 1;
            }

            &:nth-of-type(2) {
                -ms-grid-row: 1;
                -ms-grid-column: 2;
            }

            &:nth-of-type(3) {
                -ms-grid-row: 2;
                -ms-grid-column: 1;
            }

            &:nth-of-type(4) {
                -ms-grid-row: 2;
                -ms-grid-column: 2;
            }

            &:nth-of-type(5) {
                -ms-grid-row: 3;
                -ms-grid-column: 1;
            }

            &:nth-of-type(6) {
                -ms-grid-row: 3;
                -ms-grid-column: 2;
            }

            &:nth-of-type(7) {
                -ms-grid-row: 4;
                -ms-grid-column: 1;
            }

            &:nth-of-type(8) {
                -ms-grid-row: 4;
                -ms-grid-column: 2;
            }

            &:nth-of-type(9) {
                -ms-grid-row: 5;
                -ms-grid-column: 1;
            }

            &:nth-of-type(10) {
                -ms-grid-row: 5;
                -ms-grid-column: 2;
            }

            &:nth-of-type(11) {
                -ms-grid-row: 6;
                -ms-grid-column: 1;
            }

            &:nth-of-type(12) {
                -ms-grid-row: 6;
                -ms-grid-column: 2;
            }

            &:nth-of-type(13) {
                -ms-grid-row: 7;
                -ms-grid-column: 1;
            }

            &:nth-of-type(14) {
                -ms-grid-row: 7;
                -ms-grid-column: 2;
            }

            &:nth-of-type(15) {
                -ms-grid-row: 8;
                -ms-grid-column: 1;
            }

            &:nth-of-type(16) {
                -ms-grid-row: 8;
                -ms-grid-column: 2;
            }

            &:nth-of-type(17) {
                -ms-grid-row: 8;
                -ms-grid-column: 3;
            }

            &:nth-of-type(18) {
                -ms-grid-row: 9;
                -ms-grid-column: 1;
            }
        }

        &:nth-last-of-type(1),
        &:nth-last-of-type(2) {
            border-bottom: none;
        }

        img {
            max-width: 165px;
            height: auto;
            width: calc(100% - 8px);
        }
    }
    @media all and (min-width:600px) and (max-width:799px) {
        grid-template-columns: repeat(3, 1fr);
        -ms-grid-columns: 1fr 1fr 1fr;

        li {
            &:nth-of-type(3n),
            &:nth-of-type(3n+1) {
                border-image-width: 0 0 2px 0;
            }

            &:nth-last-of-type(1),
            &:nth-last-of-type(3) {
                border-image-width: 0 0 0 0;
            }

            &:nth-last-of-type(2) {
                border-image-width: 0 2px 0 2px;
            }

            &:nth-of-type(1) {
                -ms-grid-row: 1;
                -ms-grid-column: 1;
            }

            &:nth-of-type(2) {
                -ms-grid-row: 1;
                -ms-grid-column: 2;
            }

            &:nth-of-type(3) {
                -ms-grid-row: 1;
                -ms-grid-column: 3;
            }

            &:nth-of-type(4) {
                -ms-grid-row: 2;
                -ms-grid-column: 1;
            }

            &:nth-of-type(5) {
                -ms-grid-row: 2;
                -ms-grid-column: 2;
            }

            &:nth-of-type(6) {
                -ms-grid-row: 2;
                -ms-grid-column: 3;
            }

            &:nth-of-type(7) {
                -ms-grid-row: 3;
                -ms-grid-column: 1;
            }

            &:nth-of-type(8) {
                -ms-grid-row: 3;
                -ms-grid-column: 2;
            }

            &:nth-of-type(9) {
                -ms-grid-row: 3;
                -ms-grid-column: 3;
            }

            &:nth-of-type(10) {
                -ms-grid-row: 4;
                -ms-grid-column: 1;
            }

            &:nth-of-type(11) {
                -ms-grid-row: 4;
                -ms-grid-column: 2;
            }

            &:nth-of-type(12) {
                -ms-grid-row: 4;
                -ms-grid-column: 3;
            }

            &:nth-of-type(13) {
                -ms-grid-row: 5;
                -ms-grid-column: 1;
            }

            &:nth-of-type(14) {
                -ms-grid-row: 5;
                -ms-grid-column: 2;
            }

            &:nth-of-type(15) {
                -ms-grid-row: 5;
                -ms-grid-column: 3;
            }

            &:nth-of-type(16) {
                -ms-grid-row: 6;
                -ms-grid-column: 1;
            }

            &:nth-of-type(17) {
                -ms-grid-row: 6;
                -ms-grid-column: 2;
            }

            &:nth-of-type(18) {
                -ms-grid-row: 6;
                -ms-grid-column: 3;
            }
        }
    }
    @media all and (min-width:800px) {
        grid-template-columns: repeat(4, 1fr);
        -ms-grid-columns: 1fr 1fr 1fr 1fr;

        li {
            &:nth-of-type(4n),
            &:nth-of-type(4n+1) {
                border-image-width: 0 0 2px 0;
            }

            &:nth-of-type(4n-1) {
                border-image-width: 0 2px 2px 0;
            }

            &:nth-last-of-type(1),
            &:nth-last-of-type(3),
            &:nth-last-of-type(4) {
                border-image-width: 0 0 0 0;
            }

            &:nth-last-of-type(2) {
                border-image-width: 0 2px 0 0;
            }

            &:nth-last-of-type(3) {
                border-image-width: 0 2px 0 2px;
            }

            &:nth-of-type(1) {
                -ms-grid-row: 1;
                -ms-grid-column: 1;
            }

            &:nth-of-type(2) {
                -ms-grid-row: 1;
                -ms-grid-column: 2;
            }

            &:nth-of-type(3) {
                -ms-grid-row: 1;
                -ms-grid-column: 3;
            }

            &:nth-of-type(4) {
                -ms-grid-row: 1;
                -ms-grid-column: 4;
            }

            &:nth-of-type(5) {
                -ms-grid-row: 2;
                -ms-grid-column: 1;
            }

            &:nth-of-type(6) {
                -ms-grid-row: 2;
                -ms-grid-column: 2;
            }

            &:nth-of-type(7) {
                -ms-grid-row: 2;
                -ms-grid-column: 3;
            }

            &:nth-of-type(8) {
                -ms-grid-row: 2;
                -ms-grid-column: 4;
            }

            &:nth-of-type(9) {
                -ms-grid-row: 3;
                -ms-grid-column: 1;
            }

            &:nth-of-type(10) {
                -ms-grid-row: 3;
                -ms-grid-column: 2;
            }

            &:nth-of-type(11) {
                -ms-grid-row: 3;
                -ms-grid-column: 3;
            }

            &:nth-of-type(12) {
                -ms-grid-row: 3;
                -ms-grid-column: 4;
            }

            &:nth-of-type(13) {
                -ms-grid-row: 4;
                -ms-grid-column: 1;
            }

            &:nth-of-type(14) {
                -ms-grid-row: 4;
                -ms-grid-column: 2;
            }

            &:nth-of-type(15) {
                -ms-grid-row: 4;
                -ms-grid-column: 3;
            }

            &:nth-of-type(16) {
                -ms-grid-row: 4;
                -ms-grid-column: 4;
            }

            &:nth-last-of-type(3),
            &:nth-last-of-type(4) {
                border-bottom: none;
            }

            &:nth-of-type(4n + 1) {
                border-left: none;
            }
        }
    }
}